<template>
  <q-btn
    outline
    :rounded="false"
    class="full-width text-body1 min-heigh-input border-grey"
    @click="handleGoogleSignIn"
  >
    <q-img width="25px" src="~assets/img/googleLogo.png" class="q-mr-sm" />
    {{ $t('account.signIn.google') }}
  </q-btn>
</template>

<script setup lang="ts">
import { useRoute } from 'vue-router'
import { useAuthStore } from 'stores/receiver/account/auth/auth'
import { onBeforeMount } from 'vue'
import { useErrorStore } from 'stores/common/error'
import { useI18n } from 'vue-i18n'

// PROPS
const props = defineProps<{
  redirect?: string
}>()

const route = useRoute()
const authStore = useAuthStore()

onBeforeMount(() => {
  const query = route.query
  if (query.provider === 'google' && !query.code) {
    useErrorStore().backErrors.providerLogin = useI18n().t(
      'account.signIn.providerFail',
      { provider: 'google' }
    )
  }
})

const handleGoogleSignIn = async () => {
  const googleRedirect = await authStore.signInWithGoogle(
    `${window.location.origin}${props.redirect || '/login?provider=google'}`
  )
  if (googleRedirect) {
    window.location.href = googleRedirect
  }
}
</script>

<style scoped lang="scss">
.border-grey::before {
  border-color: rgba(0, 0, 0, 0.24);
}
</style>
